1<template>
    <div class="box">
        <div class="body">
            <div class="info-title bdr">
                <a-button type="link" icon="left" @click="$router.go(-1)" />{{ info.name }}
            </div>
            <div class="body-content">
                <a-row :gutter="10">
                    <a-col span="16">
                        <mytable v-if="+type == 1" :info="info" />
                        <fastTable v-else-if="+type == 2" :formObj="billInfo" />
                        <billTable v-else-if="+type == 3" :formObj="billInfo" />
                    </a-col>
                    <a-col span="8">
                        <myform
                            :billMess="billMess"
                            :params="params"
                            class="bdr"
                            :content.sync="content"
                            :fileIds.sync="fileIds"
                        />
                    </a-col>
                </a-row>
            </div>
        </div>
    </div>
</template>

<script>
import { documentBillDetail } from '@/api/modular/fileStream/documentManage'
import mytable from '@/views/fileStream/documentApproval/documentManage/table.vue'
import editor from '@/views/fileStream/documentManage/editor'
import upload from '@/views/fileStream/documentManage/upload'
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'
import billTable from '@/views/fileStream/documentSend/components/billTable.vue'
import fastTable from '@/views/fileStream/documentSend/components/fastTable.vue'
import myform from './form.vue'
export default {
    components: { mytable, myform, upload, editor, billTable, fastTable },
    data() {
        return {
            fileIds: [],
            content: '',
            info: {},
            billInfo: {}, // 拟稿发文数据
            params: {},
            billMess: {},
            type: 1,
        }
    },
    created() {
        init_wx()
        this.get_info()
    },
    methods: {
        get_info() {
            const { id, type } = this.$route.query
            if (type) {
                this.type = type
            }
            this.params = this.$route.query
            if (id) {
                documentBillDetail({ id }).then((res) => {
                    const { data } = res
                    if (!data) return
                    this.info = data
                    this.billInfo = {
                        ...data,
                        jjcd: data.urgencyDegree,
                        miji: data.secrecyDegree,
                        userId: data.draftId,
                        xzfn: String(data.fileType),
                        fileIds: data.documentFileList,
                    }
                    const { content, documentNodeList, documentFileList, approvalStatus } = res.data
                    this.content = content
                    this.fileIds = documentFileList
                    this.billMess = {
                        documentNodeList,
                        approvalStatus,
                    }
                })
            }
        },
    },
}
</script>

<style lang="less" scoped>
.flex {
    display: flex;
    justify-content: space-between;
}
.font {
    font-size: 16px;
}
.box {
    background-color: #ffffff;
    min-height: calc(100vh - 120px);
    width: 90%;
    margin: 0 auto;
    border-radius: 10px;
}
.body {
    // max-width: 1280px;
    width: 100%;
    margin: auto !important;
}
.body-content {
    padding: 10px 44px 20px;
    box-sizing: border-box;
}
.left-com {
    padding: 20px 25px;
    background: #fff;
    width: 792px;
}
.info-title {
    width: 1279px;
    height: 66px;
    background: #ffffff;

    font-size: 18px;
    color: #565656;
    margin-bottom: 20px;
    line-height: 66px;
    border-bottom: 1px solid rgb(235, 237, 240);
    button {
        margin: 0 10px;
        color: #c1c1c1;
    }
}
.bdr {
    border-radius: 4px;
}
.line {
    margin: 30px 0 15px;
    font-size: 18px;
    font-weight: bold;
    color: #3e3d3d;
}
.line:first-child {
    margin-top: 0;
}
.line::before {
    content: '';
    width: 4px;
    height: 20px;
    background: #3b9afa;
    display: inline-block;
    position: relative;
    top: 3px;
    margin-right: 10px;
}
</style>
